import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const HouseOutlinedIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M59.1562 31.5624L35.0062 7.42491L33.3875 5.80616C33.0186 5.43977 32.5198 5.23413 32 5.23413C31.4801 5.23413 30.9813 5.43977 30.6125 5.80616L4.84372 31.5624C4.46579 31.9389 4.1671 32.3872 3.96529 32.881C3.76348 33.3748 3.66264 33.904 3.66872 34.4374C3.69372 36.6374 5.52497 38.3937 7.72497 38.3937H10.3812V58.7499H53.6187V38.3937H56.3312C57.4 38.3937 58.4062 37.9749 59.1625 37.2187C59.5348 36.8475 59.8299 36.4061 60.0305 35.9201C60.2311 35.4341 60.3333 34.9132 60.3312 34.3874C60.3312 33.3249 59.9125 32.3187 59.1562 31.5624ZM35.5 54.2499H28.5V41.4999H35.5V54.2499ZM49.1187 33.8937V54.2499H39.5V39.9999C39.5 38.6187 38.3812 37.4999 37 37.4999H27C25.6187 37.4999 24.5 38.6187 24.5 39.9999V54.2499H14.8812V33.8937H8.88122L32.0062 10.7874L33.45 12.2312L55.125 33.8937H49.1187Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default HouseOutlinedIcon

export const FLOCK_PHONE_NUMBER = '(720) 703-9992'
export const GATSBY_ROOT_DOMAIN =
  process.env.GATSBY_ROOT_DOMAIN || 'https://www.flockhomes.com'
export const FLOCK_EMAIL = 'advisor@flockhomes.com'
export const INVESTOR_EMAIL = 'danielle@flockhomes.com'
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
export const PHONE_URL = 'tel:+17207039992'

export const OM_PHONE_NUMBER = '(407) 974-5463'
export const OM_INVESTOR_EMAIL = '721exchange@overmoon.com'
export const OM_PHONE_URL = 'tel:+14079745463'

import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PortfolioMapSection from '../components/PortfolioComponents/PortfolioMapSection'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import '../styles/portfolio.css'
import PortfolioHeroSection from '../components/PortfolioComponents/PortfolioHeroSection'
import PortfolioDetailedAssetView from '../components/PortfolioComponents/PortfolioDetailedAssetView'
import { useRecordPageDuration } from '../components/utils'

const pageTitle = 'Flock | Portfolio'
const pageDescription = `A better way to sell your rental property.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Portfolio = () => {
  const { getPropertySummaryDetails, getDailyMetricsV1 } =
    useStaticQuery(graphql`
      query {
        getPropertySummaryDetails {
          asOfDate
          propertyDetails {
            address {
              city
              streetNumber
              formattedStreet
              formattedAddress
              latitude
              longitude
              state
              unit
              zipcode
              primaryPhotoUrl
            }
            baths
            acquisitionDate
            beds
            halfBaths
            propertyType
            sqft
            propertyUuid
            yearBuilt
            leaseStatus
            propertyValuationHistory {
              currentValuationCents
              effectiveDatetime
            }
          }
        }
        getDailyMetricsV1 {
          dailyMetrics {
            state
            numProperties
            numOccupiedProperties
            totalSqft
            totalValuation
            totalOccupiedValuation
            totalMonthlyRentCents
            numPropertiesThisQuarter
          }
        }
      }
    `)

  const { propertyDetails, asOfDate } = getPropertySummaryDetails
  const { dailyMetrics } = getDailyMetricsV1

  useRecordPageDuration()

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="portfolio"
      headerBackground="trustBlue.main"
      asOfDate={asOfDate}
    >
      <PortfolioHeroSection />
      <PortfolioMapSection
        propertyAssets={propertyDetails}
        dailyMetrics={dailyMetrics}
      />
      <PortfolioHeroSection
        title="A Complete List of Flock's Homes"
        subtitle="When exchanging with Flock, we take on all the hassles of day-to-day management while you get ownership of our managed portfolio of homes shown below."
        titleVariant="h2"
      />
      <PortfolioDetailedAssetView assets={propertyDetails} />
    </PageWrapper>
  )
}

export default Portfolio

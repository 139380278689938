import { formatIntegerDollars, formatNumberWithCommas } from '@flock/utils'
import { Box, Paper, styled, Typography } from '@mui/material'
import React from 'react'
import { FLOCK_PHONE_NUMBER, PHONE_URL } from '../../constants'
import { LockFilledIcon } from '../icons/LockFilledIcon'
import { TrackedButton } from '../TrackedButton'
import {
  AssetViewCardProps,
  tagColorMap,
  textColorMap,
  trackingName,
} from './assetViewCardTypes'

const MapContainer = styled('div')({
  width: 'fit-content',
  alignSelf: 'center',
  textAlign: 'center',
  position: 'relative',
  maxHeight: '288px',
  maxWidth: '288px',
})

const Marker = styled('div')({
  position: 'absolute',
  background: '#03341D',
  borderRadius: '50%',
  border: '8px solid #03341D',
  width: '8px',
  height: '8px',
  marginLeft: '132px',
  marginTop: '-162px',

  '&:after': {
    position: 'absolute',
    width: '0px',
    height: '0px',
    bottom: '-30px',
    left: '-6px',
    border: '10px solid transparent',
    borderTop: '17px solid #03341D',
    content: '""',
  },
})

const AssetViewCardDesktop = (props: AssetViewCardProps) => {
  const { asset, blur, mapsUrl } = props
  const isOccupied =
    asset.leaseStatus && asset.leaseStatus.toLowerCase() !== 'vacant'

  let flockValuationString = ''
  if (
    asset?.propertyValuationHistory &&
    asset.propertyValuationHistory.length > 0
  ) {
    const valuation =
      asset.propertyValuationHistory[asset.propertyValuationHistory.length - 1]
    if (valuation?.currentValuationCents && valuation?.effectiveDatetime) {
      const formatter = new Intl.DateTimeFormat('en-US')
      flockValuationString = `${formatIntegerDollars(
        valuation.currentValuationCents / 100,
        true
      )} Valuation as of ${formatter.format(
        new Date(valuation.effectiveDatetime)
      )}`
    }
  }

  return (
    <Paper
      sx={{
        borderRadius: '16px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px',
        position: blur ? 'relative' : undefined,
      }}
    >
      {blur && (
        <Box
          position="absolute"
          display="flex"
          width="564px"
          flexDirection="column"
          alignItems="center"
          padding={{ xs: '18px', sm: '32px' }}
          gap={{ xs: '8px', sm: '16px' }}
          sx={{
            background: 'rgba(255, 255, 255, 0.8)',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
            borderRadius: '24px',
            zIndex: 1,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '64px',
          }}
        >
          <Box display="flex" gap="16px" alignItems="center">
            <Box width={{ xs: '32px', sm: '56px' }}>
              <LockFilledIcon width="28px" height="28px" />
            </Box>
            <Typography variant="h4">
              Detailed home view is exclusive content only for Flock Owners. To
              see more than a sneak peek:
            </Typography>
          </Box>
          <Box>
            <TrackedButton
              onClick={() => {
                window.open(PHONE_URL)
              }}
              trackingConfig={{ name: `${trackingName}-close` }}
              variant="primary"
              sx={{ padding: '8px 32px' }}
            >
              <Typography variant="p2">Call {FLOCK_PHONE_NUMBER}</Typography>
            </TrackedButton>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        sx={{ filter: blur ? 'blur(8px)' : undefined }}
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            justifyContent="center"
            width="464px"
            flexDirection="column"
            gap="16px"
          >
            <Box
              height="288px"
              sx={{
                borderRadius: '8px',
                backgroundImage: `url(${asset.address?.primaryPhotoUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              {isOccupied && (
                <Paper
                  sx={{
                    borderRadius: '8px',
                    width: '96px',
                    height: '32px',
                    backgroundColor:
                      tagColorMap[asset.leaseStatus || 'occupied'],
                    position: 'relative',
                    top: '8px',
                    left: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="c1"
                    color={textColorMap[asset.leaseStatus || 'occupied']}
                  >
                    {asset.leaseStatus === 'mtm'
                      ? 'OCCUPIED'
                      : asset.leaseStatus?.toUpperCase()}
                  </Typography>
                </Paper>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row" width="50%">
                <Box display="flex" flexDirection="column">
                  <Typography variant="p2">
                    {asset.address?.formattedStreet}
                  </Typography>
                  <Typography variant="p3">
                    {asset.address?.city}, {asset.address?.state}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                width="50%"
                justifyContent="right"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="p2">
                    {asset.monthlyRentCents
                      ? `${formatIntegerDollars(
                          asset.monthlyRentCents / 100,
                          true
                        )}/mo rent`
                      : ''}
                  </Typography>
                  <Typography variant="p3">{flockValuationString}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            width="288px"
            flexDirection="column"
            gap="16px"
          >
            <MapContainer>
              <Box
                component="img"
                height="288px"
                width="288px"
                sx={{ borderRadius: '8px' }}
                src={mapsUrl}
              />
              <Marker />
            </MapContainer>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                width="50%"
                justifyContent="space-evenly"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="p2">{asset.beds}</Typography>
                  <Typography variant="p3">bed</Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="p2">{asset.baths}</Typography>
                  <Typography variant="p3">bath</Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="p2">
                    {asset.sqft
                      ? formatNumberWithCommas(asset.sqft)
                      : 'unknown'}
                  </Typography>
                  <Typography variant="p3">sq ft</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                width="50%"
                justifyContent="center"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="p2">
                    {asset.propertyType === 'SFR'
                      ? 'single-family home'
                      : asset.propertyType?.toLowerCase()}
                  </Typography>
                  <Typography variant="p3">built {asset.yearBuilt}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default AssetViewCardDesktop

import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const LockFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 56}
      height={height || 56}
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M45.5 25.375H41.7812V13.125C41.7812 9.25859 38.6477 6.125 34.7812 6.125H21.2188C17.3523 6.125 14.2188 9.25859 14.2188 13.125V25.375H10.5C9.53203 25.375 8.75 26.157 8.75 27.125V48.125C8.75 49.093 9.53203 49.875 10.5 49.875H45.5C46.468 49.875 47.25 49.093 47.25 48.125V27.125C47.25 26.157 46.468 25.375 45.5 25.375ZM29.5312 38.3359V41.2344C29.5312 41.475 29.3344 41.6719 29.0938 41.6719H26.9062C26.6656 41.6719 26.4688 41.475 26.4688 41.2344V38.3359C26.0173 38.0118 25.6804 37.5528 25.5064 37.025C25.3324 36.4973 25.3304 35.9279 25.5007 35.3989C25.6709 34.8699 26.0046 34.4085 26.4537 34.0812C26.9029 33.7539 27.4443 33.5776 28 33.5776C28.5557 33.5776 29.0971 33.7539 29.5462 34.0812C29.9954 34.4085 30.3291 34.8699 30.4993 35.3989C30.6696 35.9279 30.6676 36.4973 30.4936 37.025C30.3196 37.5528 29.9827 38.0118 29.5312 38.3359ZM37.8438 25.375H18.1562V13.125C18.1562 11.4352 19.5289 10.0625 21.2188 10.0625H34.7812C36.4711 10.0625 37.8438 11.4352 37.8438 13.125V25.375Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default LockFilledIcon

import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import { AssetViewCard } from '@flock/shared-ui'
import SectionLayout from '../SharedComponents/SectionLayout'
import { abbreviationToState } from '../utils'

type PortfolioDetailedAssetViewProps = {
  assets: Core_PropertySummaryDetails[]
}

type StateCount = {
  [state: string]: number
}

type IndividualStateCount = {
  state: string
  count: number
}

const ASSETS_PER_PAGE = 3

const PortfolioDetailedAssetView = (props: PortfolioDetailedAssetViewProps) => {
  const { assets } = props

  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const FEATURED_ASSET_FORMATTED_STREETS = [
    '3708 Folkstone Dr',
    '7303 Bonnie Ridge Ct',
    '606 E Cardinal St',
    '5136 Meadow Creek Dr',
    '236 Geode Ln',
    '3517 Tarragon Dr',
    '2590 Godfrey Dr NW',
    '14808 11th Pl W',
    '556 McGraw St',
    '4144 Adams St',
    '4613 Lloyd St',
    '3233 W 11th Avenue Dr',
    '1861 E 112th Pl',
    '8024 Bayou Fountain Ave',
    '1308 W College St',
    '1211 Nashua Rd',
  ]

  const featuredAssets = assets.filter((propertyAsset) =>
    FEATURED_ASSET_FORMATTED_STREETS.includes(
      `${propertyAsset?.address?.streetNumber} ${propertyAsset?.address?.formattedStreet}`
    )
  )

  const [filteredPropertyAssets, setFilteredPropertyAssets] = useState<
    Core_PropertySummaryDetails[] | undefined
  >(featuredAssets)

  const [selectedMarket, setSelectedMarket] = useState('All')
  const [page, setPage] = useState<number>(1)

  const handleChangePage = (_event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const statesWithCountsObject = assets.reduce((total: StateCount, asset) => {
    const state = asset.address?.state || ''
    const curr = { ...total }
    curr[state] = total[state] || 0
    curr[state] += 1
    return curr
  }, {} as StateCount)

  const tempStatesWithCountsList: IndividualStateCount[] = []

  Object.keys(statesWithCountsObject).map((key) =>
    tempStatesWithCountsList.push({
      state: key,
      count: statesWithCountsObject[key],
    })
  )

  tempStatesWithCountsList.sort((a, b) => (a.count > b.count ? -1 : 1))
  tempStatesWithCountsList.unshift({ state: 'All', count: assets.length })

  useEffect(() => {
    let tempFilteredPropertyAssets: Core_PropertySummaryDetails[] | undefined
    if (selectedMarket === 'All') {
      tempFilteredPropertyAssets = featuredAssets
    } else {
      tempFilteredPropertyAssets = featuredAssets?.filter(
        (asset) => asset.address?.state === selectedMarket
      )
    }

    tempFilteredPropertyAssets = tempFilteredPropertyAssets.filter(
      (propertyAsset, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.address?.formattedStreet ===
            propertyAsset.address?.formattedStreet
        )
    )

    // Adding blurred property
    tempFilteredPropertyAssets.concat(tempFilteredPropertyAssets[0])

    setFilteredPropertyAssets(tempFilteredPropertyAssets)
    setPage(1)
  }, [selectedMarket])

  return (
    <SectionLayout
      name="portfolio-detailed-asset-view"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
      sidePadding="0px"
    >
      {!isMobile && !isTablet && (
        <Grid
          item
          xs={3}
          display="flex"
          flexDirection="column"
          sx={{ paddingBottom: '64px' }}
        >
          <Box display="flex" flexDirection="column" width="100%" gap={1}>
            {tempStatesWithCountsList.map((data) => (
              <Paper
                key={data.state}
                sx={{
                  padding: '16px',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  borderRadius: '16px',
                  boxShadow: 'none',
                  backgroundColor:
                    selectedMarket === data.state
                      ? 'green2.main'
                      : 'trustBlue.main',
                  transition: 'background-color 0.2s ease-in-out',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedMarket(data.state)}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Typography
                    variant="p2"
                    fontWeight={selectedMarket === data.state ? 500 : 400}
                  >
                    {abbreviationToState[data.state] || data.state}
                  </Typography>
                  <Typography
                    variant="p3"
                    fontWeight={selectedMarket === data.state ? 500 : 400}
                  >
                    {data.count}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={isMobile || isTablet ? 12 : 9}
        display="flex"
        flexDirection="column"
        sx={{ paddingBottom: '64px' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          gap="16px"
        >
          {filteredPropertyAssets
            ?.slice(
              (page - 1) * ASSETS_PER_PAGE,
              (page - 1) * ASSETS_PER_PAGE + ASSETS_PER_PAGE
            )
            .map((asset) => (
              <AssetViewCard asset={asset} />
            ))}
          {filteredPropertyAssets!.length / ASSETS_PER_PAGE <= page && (
            <AssetViewCard asset={featuredAssets![0]} blur />
          )}
        </Box>
        <Box
          pt="16px"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Pagination
            count={
              filteredPropertyAssets && filteredPropertyAssets.length > 0
                ? Math.ceil(filteredPropertyAssets.length / ASSETS_PER_PAGE)
                : 1
            }
            sx={{
              '& button': {
                fontFamily: 'Outfit!important',
              },
            }}
            defaultPage={1}
            siblingCount={1}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default PortfolioDetailedAssetView

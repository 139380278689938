import React from 'react'
import AssetViewCardDesktop from './AssetViewCardDesktop'
import AssetViewCardTablet from './AssetViewCardTablet'
import AssetViewCardMobile from './AssetViewCardMobile'
import useAssetViewCard from './useAssetViewCard'
import { AssetViewCardProps } from './assetViewCardTypes'

const AssetViewCard = (props: AssetViewCardProps) => {
  const presentationalProps = useAssetViewCard(props)

  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <AssetViewCardMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <AssetViewCardTablet {...presentationalProps} />
  }
  return <AssetViewCardDesktop {...presentationalProps} />
}

export default AssetViewCard

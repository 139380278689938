import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'

export type AssetViewCardProps = {
  asset: Core_PropertySummaryDetails
  blur?: boolean
  mapsUrl?: string
}

export const trackingName = 'portfolio-featured-flock-homes'

export const tagColorMap: Record<string, string> = {
  occupied: 'green4.main',
  mtm: 'green4.main',
  vacant: 'errorRed.main',
  renovating: 'softGold.main',
  leasing: '#FFD9B7',
}

export const textColorMap: Record<string, string> = {
  occupied: '#FFFFFF',
  mtm: '#FFFFFF',
  vacant: '#FFFFFF',
  renovating: 'primary.main',
  leasing: 'primary.main',
}

import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const BathroomIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M21.3345 37.3344C21.3345 35.8677 22.5345 34.6677 24.0011 34.6677C25.4678 34.6677 26.6678 35.8677 26.6678 37.3344C26.6678 38.801 25.4678 40.001 24.0011 40.001C22.5345 40.001 21.3345 38.801 21.3345 37.3344ZM32.0011 40.001C33.4678 40.001 34.6678 38.801 34.6678 37.3344C34.6678 35.8677 33.4678 34.6677 32.0011 34.6677C30.5345 34.6677 29.3345 35.8677 29.3345 37.3344C29.3345 38.801 30.5345 40.001 32.0011 40.001ZM40.0011 40.001C41.4678 40.001 42.6678 38.801 42.6678 37.3344C42.6678 35.8677 41.4678 34.6677 40.0011 34.6677C38.5345 34.6677 37.3345 35.8677 37.3345 37.3344C37.3345 38.801 38.5345 40.001 40.0011 40.001ZM32.0011 20.001C27.3078 20.001 23.4145 23.4944 22.7745 28.001H41.2545C40.923 25.7816 39.8074 23.7544 38.1098 22.2868C36.4123 20.8192 34.2451 20.0083 32.0011 20.001M32.0011 16.001C39.3611 16.001 45.3345 21.9744 45.3345 29.3344V32.001H18.6678V29.3344C18.6678 21.9744 24.6411 16.001 32.0011 16.001ZM24.0011 48.001C25.4678 48.001 26.6678 46.801 26.6678 45.3344C26.6678 43.8677 25.4678 42.6677 24.0011 42.6677C22.5345 42.6677 21.3345 43.8677 21.3345 45.3344C21.3345 46.801 22.5345 48.001 24.0011 48.001ZM32.0011 48.001C33.4678 48.001 34.6678 46.801 34.6678 45.3344C34.6678 43.8677 33.4678 42.6677 32.0011 42.6677C30.5345 42.6677 29.3345 43.8677 29.3345 45.3344C29.3345 46.801 30.5345 48.001 32.0011 48.001ZM40.0011 48.001C41.4678 48.001 42.6678 46.801 42.6678 45.3344C42.6678 43.8677 41.4678 42.6677 40.0011 42.6677C38.5345 42.6677 37.3345 43.8677 37.3345 45.3344C37.3345 46.801 38.5345 48.001 40.0011 48.001ZM53.3345 10.6677H10.6678V53.3344H53.3345V10.6677ZM53.3345 5.33435C56.2678 5.33435 58.6678 7.73435 58.6678 10.6677V53.3344C58.6678 56.2677 56.2678 58.6677 53.3345 58.6677H10.6678C7.73447 58.6677 5.33447 56.2677 5.33447 53.3344V10.6677C5.33447 7.73435 7.73447 5.33435 10.6678 5.33435H53.3345Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default BathroomIcon

import { useMediaQuery, useTheme } from '@mui/material'
import { GOOGLE_MAPS_API_KEY } from '../../constants'
import { generateGoogleMapUrl } from '../../utils'
import { AssetViewCardProps } from './assetViewCardTypes'

const useAssetViewCard = (props: AssetViewCardProps) => {
  // Presentational layout hooks
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const { asset, blur } = props

  const mapsUrl = generateGoogleMapUrl({
    type: 'staticmap',
    location: `${asset.address?.formattedAddress}`,
    center: `${asset.address?.latitude},${asset.address?.longitude}`,
    size: '288x288',
    zoom: 14,
    scale: 2,
    key: GOOGLE_MAPS_API_KEY,
    style: `feature:poi|visibility:off`,
  })

  return {
    isMobile,
    isTablet,

    asset,
    mapsUrl,
    blur,
  }
}

export default useAssetViewCard

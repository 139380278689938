import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const BedroomIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M48.9345 30.5344V24.001C48.9345 21.0677 46.5345 18.6677 43.6011 18.6677H34.6678C33.6811 18.6677 32.7478 18.9877 32.0011 19.521C31.2545 18.9877 30.3211 18.6677 29.3345 18.6677H20.4011C17.4678 18.6677 15.0678 21.0677 15.0678 24.001V30.5344C14.0011 31.761 13.3345 33.361 13.3345 35.121V45.3344H17.3345V41.3344H46.6678V45.3344H50.6678V35.121C50.6678 33.361 50.0011 31.761 48.9345 30.5344ZM44.6678 28.001H34.0011V22.6677H44.6678V28.001ZM19.3345 22.6677H30.0011V28.001H19.3345V22.6677ZM46.6678 37.3344H17.3345V34.6677C17.3345 33.201 18.5345 32.001 20.0011 32.001H44.0011C45.4678 32.001 46.6678 33.201 46.6678 34.6677V37.3344ZM53.3345 10.6677V53.3344H10.6678V10.6677H53.3345ZM53.3345 5.33435H10.6678C7.73447 5.33435 5.33447 7.73435 5.33447 10.6677V53.3344C5.33447 56.2677 7.73447 58.6677 10.6678 58.6677H53.3345C56.2678 58.6677 58.6678 56.2677 58.6678 53.3344V10.6677C58.6678 7.73435 56.2678 5.33435 53.3345 5.33435Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default BedroomIcon

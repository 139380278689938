import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import SectionLayout from '../SharedComponents/SectionLayout'

type PortfolioHeroSectionProps = {
  title?: string
  subtitle?: string
  titleVariant?: 'h1' | 'h2'
}

const PortfolioHeroSection = (props: PortfolioHeroSectionProps) => {
  const { title, subtitle, titleVariant } = props
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SectionLayout
      name="portfolio-hero"
      backgroundColor="trustBlue.main"
      verticalPadding={isMobile ? '24px' : '32px'}
    >
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent={isMobile || isTablet ? 'left' : 'center'}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobile || isTablet ? 'left' : 'center'}
          gap="16px"
        >
          <Typography color="moneyGreen.main" variant={titleVariant || 'h1'}>
            {title}
          </Typography>
          <Typography
            color="moneyGreen.main"
            variant={isMobile || isTablet ? 'p3' : 'h4'}
            align={isMobile || isTablet ? 'left' : 'center'}
          >
            {subtitle}
          </Typography>
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default PortfolioHeroSection

PortfolioHeroSection.defaultProps = {
  title: `Flock's Portfolio`,
  subtitle: `Discover low-cost, diversified, passive real estate ownership with Flock.`,
  titleVariant: `h1`,
}
